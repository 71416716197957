import styles from './Footer.module.css';

import logo from '../images/9fea90b7958bc90302276e6ee2cfbdb7.png';
function Footer() {
    return (
        <div className={styles.container}>
            <div className={styles.logoSection}>
                <img src={logo} alt="Frey App" className={styles.image} />
                <span className={styles.text}>FREY</span>
                </div>
            <div className={styles.linksSection}>
                <a href="/terms_of_use_frey.html" className={styles.link}>Terms of use</a>
                <a href="/privacy_policy_frey.html" className={styles.link}>Privacy policy</a>
            </div>
        </div>
    )
}

export default Footer