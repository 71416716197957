import styles from './Header.module.css';

import logo from '../images/9fea90b7958bc90302276e6ee2cfbdb7.png';

function Header() {
    return (
        <div className={styles.container}>
            <img src={logo} alt="Frey App" className={styles.image} />
            <span className={styles.text}>FREY</span>
            <button 
                className={styles.contactButton} 
                onClick={() => window.location.href = 'mailto:contact@freyapp.com'}
            >Contact Us
            </button>
        </div>
    );
}

export default Header;