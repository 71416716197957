import styles from './Features.module.css';
import miniIcon from '../images/head.png';

function Features() {
    const _title = "FEATURES"
    const _text = "State-of-the-art filters for seamless beautification"

    const _miniTitle = "Easy to use"
    const _miniText = "Just upload a photo and tap on a filter - it is as simple as that!"
    return (
        <div className={styles.container}>
            <h2 className={styles.title}>{_title}</h2>
            <p className={styles.text}>{_text}</p>
            <div className={styles.miniContainer}>
                <img src={miniIcon} alt="Frey" className={styles.miniIcon} />
                <h3 className={styles.miniTitle}>{_miniTitle}</h3>
                <p className={styles.miniText}>{_miniText}</p>
            </div>
        </div>
    );
}

export default Features;