import styles from './Download.module.css';

import Buttons from './Buttons.jsx';
import phoneImage from '../images/phone.png';


function Download() {
    const _title = "Get FREY"
    const _text = "Download the app now and get stunningly beautiful portraits in just 1 tap!"
    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}> 
                <Buttons title={_title} text={_text} />
                <img src={phoneImage} alt="phone" className={styles.phoneImage} />
            </div>
        </div>
    );
}

export default Download;