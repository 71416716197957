import styles from './Top.module.css';
import Buttons from './Buttons';
import body from '../images/body.png';
import stylesImage from '../images/styles.png';

function Top() {

    const _title = "35+ STYLES"
    const _text = "Multiple styles to instantly transform your portraits"

    return (
        <div className={styles.container}>
            <Buttons />
            <div className={styles.imageSection}>
                <img src={body} alt="Frey" className={styles.body} />
            </div>
            <h2 className={styles.stylesTitle}>{_title}</h2>
            <p className={styles.text}>{_text}</p>
            <img src={stylesImage} alt="Frey" className={styles.stylesImage} />
        </div>
    );
}

export default Top;