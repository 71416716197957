import React from 'react';
import styles from './App.module.css';

import Header from './Header.jsx';
import Footer from './Footer.jsx';
import Top from './Top.jsx';
import Features from './Features.jsx';
import Download from './Download.jsx';

function App() {
  return (
    <div className={styles.container}>
      <Header />
      <Top />
      <Features />
      <Download />
      <Footer />
    </div>
  );
}

export default App;
