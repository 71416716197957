import styles from './Buttons.module.css';
import appstore from '../images/appstore.png';

function Buttons({title, text}) {
    const _title = "One-Tap Face Editor"
    const _text = "Frey makes portrait editing effortless with just one tap. Enhance your photos to look brighter, more natural, and beautiful without spending hours on adjustments. Create the perfect look instantly with a single touch!"

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const storeUrl = isIOS 
      ? 'itms-apps://apps.apple.com/app/id6736707486'
      : 'https://apps.apple.com/app/id6736707486';

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>{title || _title}</h2>
            <p className={styles.text}>{text || _text}</p>
            <a href={storeUrl}
               target="_blank" 
               rel="noopener noreferrer" 
               className={styles.storeLink}>
                <img src={appstore} alt="Download on App Store" className={styles.storeImage} />
            </a>
        </div>
    );
}

export default Buttons;